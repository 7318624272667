import React from "react";
import Layout from "../components/Layout/Layout";
import styled from "styled-components";
import SEO from "../components/Seo";
import CardProductHypercast from "../components/CardProductHypercast";
import CardProductAPI from "../components/CardProductAPI";
import HsForm from "../components/HsForm";
import ClientsGray from "../components/Homepage/ClientsGray";
import LazyLoad from "react-lazyload";
import { Container, Box, T2, T3, Lead, Image, T5, Button } from "../styles/Styles";
import theme from "../styles/theme";
import info from "../img/historical-scheme-light.png";
import heart from "../img/icon-hex-fresh-heart.svg";
import o3 from "../img/icon-hex-fresh-o3.svg";
import pm10 from "../img/icon-hex-fresh-pm10.svg";
import pm25 from "../img/icon-hex-fresh-pm25.svg";
import co from "../img/icon-hex-fresh-co.svg";
import no2 from "../img/icon-hex-fresh-no2.svg";
import so2 from "../img/icon-hex-fresh-so2.svg";
import poll from "../img/icon-hex-fresh-pollutant.svg";
import globe from "../img/icon-hex-fresh-globe.svg";
import wind from "../img/icon-hex-fresh-wind.svg";
import hum from "../img/icon-hex-fresh-humidity.svg";
import rain from "../img/icon-hex-fresh-rain.svg";
import temp from "../img/icon-hex-fresh-temp.svg";
import eye from "../img/icon-hex-fresh-eye.svg";
import dew from "../img/icon-hex-fresh-dew.svg";
import cloud from "../img/icon-hex-fresh-cloud.svg";
import press from "../img/icon-hex-fresh-pressure.svg";
import sol from "../img/icon-hex-fresh-solar.svg";
import car from "../img/icon-hex-fresh-car.svg";
import light from "../img/icon-hex-fresh-lightning.svg";
import flood from "../img/icon-hex-fresh-flood.svg";
import tree from "../img/icon-hex-fresh-tree.svg";
import fire from "../img/icon-hex-fresh-fire.svg";
import plus from "../img/icon-hex-fresh-plus.svg";
import luks from "../img/ari-luks-fresh.png";
import via from "../img/via-logo.svg";
import aq from "../img/aq-info-fresh.png";
import weather from "../img/weather-column-thumb.png";
import featureInfo from "../img/feature-information.png";
import featureIns from "../img/feature-insights.png";
import featureAct from "../img/feature-actions.png";
import heroBg from "../img/half-hex-fresh.svg";

const solutions = "https://cms2.climacell.co/wp-content/uploads/2020/01/global-solutions-thumb.gif";
const ogImg = "https://cms2.climacell.co/wp-content/uploads/2020/02/aq-og-image.jpg";

const features = [
  {
    name: "大気質",
    items: [
      { icon: heart, text: "健康への推奨事項" },
      { icon: o3, text: "O3" },
      { icon: pm10, text: "PM10" },
      { icon: pm25, text: "PM2.5" },
      { icon: co, text: "CO" },
      { icon: no2, text: "NO2" },
      { icon: so2, text: "SO2" },
      { icon: poll, text: "一次汚染物質" },
      { icon: globe, text: "グローバル大気質インデックス" },
      { icon: tree, text: "花粉" }
    ],
    img: aq,
    cap: "グリッド化されたデータは、世界の至る場所のもっとも正確な情報を提供します"
  },
  {
    name: "気象予報",
    items: [
      { icon: wind, text: "風速と方向" },
      { icon: hum, text: "湿度" },
      { icon: rain, text: "降水量タイプと激しさ" },
      { icon: temp, text: "温度" },
      { icon: eye, text: "可視性" },
      { icon: dew, text: "露点" },
      { icon: cloud, text: "雲量/底/高度" },
      { icon: press, text: "気圧" },
      { icon: sol, text: "日射" }
    ],
    img: weather,
    cap: "Hyper-local, global, street-level weather data"
  }, {
    name: "グローバルソリューション",
    items: [
      { icon: car, text: "道路リスクスコア" },
      { icon: fire, text: "火災インデックス" },
      { icon: light, text: "雷予報" },
      { icon: flood, text: "都市部洪水予報" }
    ],
    img: solutions,
    cap: "Multiple proprietary global models"
  }
];

export default ({ location }) => {
  const [selected, setSelected] = React.useState(0);
  return (
    <Layout page="aq">
      <SEO
        title="大気質 | tomorrow.io"
        pathname={location.pathname}
        image={ogImg}
      />

      <img src={heroBg} className="bg-hero-hp" alt=""/>

      <Container pb={[4, 5, "20vh"]}>

        <Box display="flex" flexWrap="wrap">
          <Box
            width={[1, 1 / 2]}
            pt={["2rem", "15vh", "20vh"]}
            pr={[0, "3rem"]}
          >
            <T2 as="h1" mb={4}>
              世界初、そして唯一の健康<br/>
              オペレーション効率化<br/>
              プラットフォーム
            </T2>
            <Box minHeight={40} mb={3}>
              <HsForm
                portalId="3777859"
                sfdcCampaignId="701460000007ZaMAAU"
                formId="18648a8e-87cc-4fd2-b44e-7eeb553d0803"
                cssClass="hs-form-unstyled hs-form-unstyled-email"
                submitButtonClass="btn btn-primary"
              />
            </Box>

          </Box>

        </Box>

      </Container>

      <VideoWrap controls={false} autoPlay muted playsInline loop>
        <source src="https://cms2.climacell.co/wp-content/uploads/2020/02/aq-map.mp4" type="video/mp4"/>
      </VideoWrap>

      <Container py={5}>
        <Box fontSize={[20, 26]} textAlign="center" mb={4} mx="auto" width='80%'>
          世界中の人々が信頼を寄せるブランドに導入されていることは、誇りです
        </Box>
        <ClientsGray/>
      </Container>

      <Container pt={[0, 5]} pb={6} textAlign="center" maxWidth={["100%", "700px"]}>
        <T2 mb={2}>
          健康オペレーション効率化プラットフォームがもたらす事は？
        </T2>
        <Lead>
          H.O.O.P.<Box as="sup" fontSize={1}>™</Box>は大気質の気づきとハイパー・ローカルな<br/>
          気象データを組み合わせ、事業者がオペレーションにおいて、<br/>
          健康リスクを考慮した優先順序をつけて計画することを可能とします。
        </Lead>
      </Container>

      <Box textAlign="center" py={[4, 5]}>
        <Container>
          <Box width={[1, 7 / 8]} mx="auto" position="relative">
            <T3 mb={[4, 5]} maxWidth={["100%", "800px"]} mx="auto">
              あなたのビジネスに同時に影響を与える<br/>
              ハイパー・ローカルな事象を追跡
            </T3>
            <Box fontSize={150} fontWeight={900} color="#DAF5EF" position="absolute" zIndex={-1} top={-150}
                 left={0} right={0} mx="auto">
              01
            </Box>
            <Box
              display="flex"
              justifyContent="space-around"
              flexWrap="wrap"
            >
              {features.map((feat, i) => {
                const active = selected === i;
                return (
                  <Box
                    width={300}
                    mb={4}
                    position="relative"
                    bg="white"
                    onClick={() => setSelected(i)} style={{ cursor: "pointer" }}
                    key={i}
                  >
                    <Box
                      height="100%" position="relative"
                      top={active ? -10 : 0}
                      boxShadow={active ? "0 1px 10px rgba(0,0,0,.1)" : "none"}
                      border="1px solid rgba(0,0,0,.05)"
                      borderRadius={8}
                    >
                      <Box
                        borderTopLeftRadius={8}
                        borderTopRightRadius={8}
                        textAlign="center"
                        bg={active ? theme.color.fresh : theme.color.light}
                        color={active ? "white" : "black"}
                        p={2}
                      >
                        <Lead mb={0} fontWeight={800}>{feat.name}</Lead>
                      </Box>
                      <Box p={3}>
                        {feat.items.map((item, i) => (
                          <Box mb={2} display="flex" alignItems="flex-start" key={i} textAlign="left">
                            <Image src={item.icon} alt="" mr={2}/>
                            <Box>{item.text}</Box>
                          </Box>
                        ))}
                        <Box mb={2} display="flex">
                          <Image src={plus} alt="" mr={2}/>
                          <Box>その他・・・</Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>

            <LazyLoad height={300} offset={600} once>
              {features.map((feat, i) => (
                <Box display={selected === i ? "block" : "none"} key={i}>
                  <Image mb={3} src={feat.img} borderRadius={8} width={460} alt=""/>
                  <Box>{feat.cap}</Box>
                </Box>
              ))}
            </LazyLoad>


          </Box>
        </Container>
      </Box>

      <Box textAlign="center" bg={theme.color.light} pt="180px" pb={6}>
        <Container>
          <Box width={[1, 7 / 8]} mx="auto" position="relative" zIndex={1}>
            <T3 mb={[4, 5]} maxWidth={["100%", "800px"]} mx="auto">
              これらの事象がいつビジネスに<br/>リスクをもたらすかを理解する
            </T3>
            <Box fontSize={150} fontWeight={900} color="#E0E0E0" position="absolute" zIndex={-1} top={-150}
                 left={0} right={0} mx="auto">
              02
            </Box>
            <Image width={993} src={info} alt="Understand when those elements will put your business at risk"/>
          </Box>
        </Container>
      </Box>

      <Box pt="180px">
        <Container>
          <Box width={[1, 7 / 8]} mx="auto" position="relative">
            <T3 mb={[4, 5]} maxWidth={["100%", "800px"]} mx="auto" textAlign="center">
              チーム全体の運用プロトコルを自動化する
            </T3>
            <Box fontSize={150} fontWeight={900} color="#DFEDFE" position="absolute" zIndex={-1} top={-150}
                 left={0} right={0} mx="auto" textAlign="center">
              03
            </Box>
            <Box display="flex" flexWrap="wrap">
              <Box width={[1, 1 / 3]} mb={4} px={3}>
                <Box textAlign="center">
                  <Image
                    mb={4}
                    maxWidth={216}
                    src={featureInfo}
                    alt="Providing you with the right information"
                  />
                </Box>
                <T5 mb={3} fontWeight={800}>
                  正しい情報の提供
                </T5>
                <p>
                  世界で最も正確な、気象データを“過去、<nobr>リアルタイム、</nobr>予報”でグローバルに入手
                </p>
              </Box>
              <Box width={[1, 1 / 3]} mb={4} px={3}>
                <Box textAlign="center">
                  <Image
                    mb={4}
                    maxWidth={294}
                    src={featureIns}
                    alt="Equipping your business with invaluable insights"
                  />
                </Box>
                <T5 mb={3} fontWeight={800}>
                  あなたのビジネスに貴重な<nobr>気づき</nobr>を提供
                </T5>
                <p>
                  天候がビジネスに及ぼす影響の<nobr>インサイトをチームに提供</nobr>
                </p>
              </Box>
              <Box width={[1, 1 / 3]} mb={4} px={3}>
                <Image
                  mb={4}
                  width={286}
                  src={featureAct}
                  alt="Driving collaboration and actions"
                />
                <T5 mb={3} fontWeight={800}>
                  コラボレーションと<nobr>行動を導く</nobr>
                </T5>
                <p>
                  気象事象に計画性をもって対策、<nobr>そしてより良い意思決定をする</nobr>
                </p>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box pt={6}>
        <Container>
          <Box position="relative">
            <T3 mb={[4, 5]} maxWidth={["100%", "800px"]} mx="auto" textAlign="center">
              すべてを一元管理
            </T3>
            <Box fontSize={150} fontWeight={900} color="#F6F6F6" position="absolute" zIndex={-1} top={-150}
                 left={0} right={0} mx="auto" textAlign="center">
              04
            </Box>
            <Box mb={5} display="flex" flexWrap="wrap">
              <Box width={[1, 1 / 2]} px={3}>
                <CardProductHypercast noLink/>
              </Box>
              <Box width={[1, 1 / 2]} px={3}>
                <CardProductAPI noLink/>
              </Box>
            </Box>

            <Box textAlign="center" py={3}>
              <Button
                as="a"
                display={["block", "inline-block"]}
                mb={[3, 0]}
                id="aq-cta-1"
                href="https://www.tomorrow.io/air-quality/hypercast/"
              >
                H.O.O.P.<Box as="sup" fontSize={1}>™</Box>デモを予約
              </Button>{" "}
              <Button
                as="a"
                outline="1"
                display={["block", "inline-block"]}
                mb={[3, 0]}
                id="aq-cta-api-1"
                href="https://www.tomorrow.io/air-quality/api/"
              >
                APIキーを入手
              </Button>
            </Box>

          </Box>
        </Container>
      </Box>

      <Box py={5}>
        <Container>
          <LazyLoad height={500} once>
            <Box display="flex" flexWrap="wrap" justifyContent="center" mb={4}>
              <Box width={["90%", "150px"]} mr={[0, 4, 5]} mb={3} textAlign="center">
                <Image width={150} src={luks} alt="Ari Luks, Director of Global economics"/>
              </Box>
              <Box width={["90%", "50%"]}>
                <Lead>
                tomorrow.ioの予報は、正確で、リアルタイムの実用的な気づきを提供してくれます。tomorrow.ioのプロダクトに大変感心し、私たちのオペレーション意思決定や、顧客に質の高いサービスを提供するのに役立っています。
                </Lead>
                <Image mb={1} src={via} width={50} alt=""/>
                <div>アリ・ラックス氏、Director of Global economics</div>
              </Box>
            </Box>

          </LazyLoad>
        </Container>
      </Box>

    </Layout>
  );
};


const VideoWrap = styled.video`
  position: static;
  background: #2b2649;
  width: 100%;
  transform: translateY(0);
  @media screen and (min-width: 768px) {
    width: 50vw;
    position: absolute;
    right: 0;
    top: 20vh;
    box-shadow: 0 15px 30px rgba(0,0,0,.3);
  }
  @media screen and (min-width: 1600px) {
    width: 820px;
  }
`;